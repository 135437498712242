












import { Component, mixins } from 'nuxt-property-decorator';

import OnResizeMixin from '@/mixins/onResize';

@Component
export default class DefaultLayout extends mixins(OnResizeMixin) {
  head () {
    return {
      meta: [{ hid: 'og:url', property: 'og:url', content: `${this.$config.baseUrl}${this.$nuxt.$route.path}` }],
    };
  }

  created () {
    if (process.client)
      window.scrollTo(0, 0);
  }
}
