import { MutationTree } from 'vuex';

interface NavigationState {
  currentSection: number,
  lightTheme: boolean,
  scrollTriggerPos: number,
}

export const state: () => NavigationState = () => ({
  currentSection: 0,
  lightTheme: false,
  scrollTriggerPos: 0,
});

export const mutations: MutationTree<NavigationState> = {
  setCurrentSection (state, payload: number) {
    state.currentSection = payload;
  },
  setLightTheme (state, payload: boolean) {
    state.lightTheme = payload;
  },
  setScrollTriggerPos (state, payload: number) {
    state.scrollTriggerPos = payload;
  },
};
