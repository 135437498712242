




import { Vue, Component, Prop } from 'nuxt-property-decorator';
@Component
export default class Ie11Render extends Vue {
  @Prop({ type: String, required: true })
    text!: string;

  @Prop({ type: String, required: true })
    href!: string;
}
