import { MutationTree } from 'vuex';

interface AppBounds {
  vw: number,
  vh: number,
}

interface AppState {
  isReady: boolean,
  bounds: AppBounds,
}

export const state: () => AppState = () => ({
  isReady: false,
  bounds: {
    vw: 0,
    vh: 0,
  },
});

export const mutations: MutationTree<AppState> = {
  setIsReady (state, payload: boolean) {
    state.isReady = payload;
  },
  setBounds (state) {
    state.bounds.vw = window.visualViewport.width | window.innerWidth;
    state.bounds.vh = window.visualViewport.height | window.innerHeight;
  },
};
