

















import { Component, Vue } from 'nuxt-property-decorator';

let CookieLaw;
if (process.client)
  CookieLaw = require('vue-cookie-law').default;

@Component({
  components: {
    CookieLaw,
  },
})
export default class CookieConsent extends Vue {

}
