









import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component({
  layout: 'default',
})
export default class ErrorPage extends Vue {
  @Prop(Object)
    error!: any;

  backToIndexText = 'Back to start';

  get statusCode () {
    const code = (this.error && this.error.statusCode) || 500;
    return code.toString();
  }

  get errorMessage () {
    if (this.error && this.error.statusCode === 404)
      return 'Page not found';
    else
      return this.error.message || 'Something went wrong';
  }
}
