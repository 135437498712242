


















import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class Button extends Vue {
  @Prop({ type: String, default: 'button' })
    tag!: 'button' | 'nuxt-link' | 'a' | 'div';

  @Prop({ type: String, required: false })
    to!: string;

  @Prop({
    type: String,
    default: 'secondary',
    validator (value) { return ['primary', 'secondary', 'light', 'dark'].includes(value) },
  })
    variant!: string;

  @Prop({ type: Boolean, default: false })
    outline!: boolean;

  @Prop({ type: Boolean, default: false })
    large!: boolean;
}
