import { Vue, Component } from 'nuxt-property-decorator';
import debounce from 'lodash.debounce';

@Component
export default class OnResizeMixin extends Vue {
  beforeMount () {
    if (process.client) {
      window.addEventListener('resize', debounce(this.$_resize, 250));
      window.addEventListener('orientationchange', this.$_resize);
      this.$_resize_commit();
    }
  }

  beforeDestroy () {
    window.removeEventListener('resize', debounce(this.$_resize, 250));
    window.removeEventListener('orientationchange', this.$_resize);
  }

  $_resize_commit () {
    this.$store.commit('app/setBounds');
    this.$_resize_setVhVariable();
  }

  $_resize () {
    this.$_resize_commit();
    this.$nuxt.$emit('onResize', this.$store.state.app.bounds);
  }

  $_resize_setVhVariable () {
    document.documentElement.style.setProperty(
      '--vh', `${this.$store.state.app.bounds.vh / 100}px`,
    );
  }
}
