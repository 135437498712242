





import { Component, Vue } from 'nuxt-property-decorator';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

@Component
export default class AppReveal extends Vue {
  get appIsReady () {
    return this.$store.state.app.isReady;
  }

  mounted () {
    if (!this.appIsReady)
      if (document.readyState !== 'complete')
        window.addEventListener('load', this.hideAppRevealer);
      else
        this.hideAppRevealer();
  }

  hideAppRevealer () {
    /**
     * For more complex intro animations, remove the vue <transition> and
     * add a GSAP timeline in here, and finish with app/setIsReady commit.
    */
    this.$store.commit('app/setIsReady', true);
    window.removeEventListener('load', this.hideAppRevealer);
    ScrollTrigger.refresh();
    document.documentElement.classList.add('-app-is-ready');
  }
}
